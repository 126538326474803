@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
$icomoon-font-family: "etabl" !default;
$icomoon-font-path: "../assets/fonts" !default;

$etabl-icon-side-tg: "\eaa3";
$etabl-icon-eye: "\eaa2";
$etabl-icon-000000659: "\eaa1";
$etabl-icon-download_app: "\eaa0";
$etabl-icon-ru-store: "\ea9f";
$etabl-icon-calendar_new: "\ea9e";
$etabl-icon-point: "\ea9c";
$etabl-icon-glassesAccessibility: "\ea9d";
$etabl-icon-rx_e: "\ea9b";
$etabl-icon-app-gellary: "\ea98";
$etabl-icon-app-store: "\ea99";
$etabl-icon-google-play: "\ea9a";
$etabl-icon-ok-round: "\ea94";
$etabl-icon-side-articles-clear: "\ea95";
$etabl-icon-whats-app: "\ea96";
$etabl-icon-copy-round: "\ea97";
$etabl-icon-prod-analogs: "\ea8d";
$etabl-icon-prod-instruction: "\ea8e";
$etabl-icon-prod-drugstores: "\ea8f";
$etabl-icon-prod-overall: "\ea90";
$etabl-icon-prod-reviews: "\ea91";
$etabl-icon-prod-additional: "\ea92";
$etabl-icon-prod-formfactor: "\ea93";
$etabl-icon-side-articles: "\ea8c";
$etabl-icon-ortoped: "\ea87";
$etabl-icon-beauty: "\ea8a";
$etabl-icon-optics: "\ea8b";
$etabl-icon-vk-round: "\ea80";
$etabl-icon-telegram: "\ea85";
$etabl-icon-devices: "\ea86";
$etabl-icon-virus: "\ea88";
$etabl-icon-vitamins: "\ea89";
$etabl-icon-all-night: "\ea84";
$etabl-icon-wide-payment: "\ea83";
$etabl-icon-plus-square: "\ea81";
$etabl-icon-edit-mobile: "\ea82";
$etabl-icon-rx-ok: "\ea7e";
$etabl-icon-settings: "\ea7f";
$etabl-icon-side-partners: "\ea7c";
$etabl-icon-business: "\ea79";
$etabl-icon-franchise: "\ea7a";
$etabl-icon-pvz: "\ea7b";
$etabl-icon-attach: "\ea78";
$etabl-icon-notification: "\ea77";
$etabl-icon-scan: "\ea75";
$etabl-icon-rx: "\ea76";
$etabl-icon-message: "\ea74";
$etabl-icon-coupon: "\ea73";
$etabl-icon-copy: "\ea70";
$etabl-icon-exit: "\ea71";
$etabl-icon-share: "\ea72";
$etabl-icon-ruble: "\ea6f";
$etabl-icon-side-sale: "\ea6e";
$etabl-icon-long-arrow-down: "\ea6c";
$etabl-icon-long-arrow-up: "\ea6d";
$etabl-icon-info_warning: "\ea6b";
$etabl-icon-char-filled: "\ea69";
$etabl-icon-chat-round: "\ea6a";
$etabl-icon-side-paper: "\ea68";
$etabl-icon-star-fill: "\ea67";
$etabl-icon-star: "\ea62";
$etabl-icon-VK: "\ea61";
$etabl-icon-return: "\e9f6";
$etabl-icon-delivery_two: "\ea65";
$etabl-icon-delivery_one: "\ea66";
$etabl-icon-000000613: "\ea64";
$etabl-icon-heart-mini: "\ea63";
$etabl-icon-car_delivery: "\ea5f";
$etabl-icon-file-pdf: "\ea59";
$etabl-icon-loading: "\ea5a";
$etabl-icon-mortar-board: "\ea5b";
$etabl-icon-graph: "\ea5c";
$etabl-icon-union: "\ea5d";
$etabl-icon-paintcan: "\ea5e";
$etabl-icon-ellipse: "\e9f4";
$etabl-icon-check-m-path1: "\e9f5";
$etabl-icon-check-m-path2: "\e9f9";
$etabl-icon-profile-loyalty: "\ea58";
$etabl-icon-side-loyalty: "\ea4d";
$etabl-icon-side-delivery: "\ea4e";
$etabl-icon-side-policy: "\ea4c";
$etabl-icon-side-return: "\ea51";
$etabl-icon-side-vacancies: "\ea4f";
$etabl-icon-side-contacts: "\ea50";
$etabl-icon-side-payment: "\ea52";
$etabl-icon-side-locatons: "\ea53";
$etabl-icon-side-how-order: "\ea54";
$etabl-icon-side-faq: "\ea55";
$etabl-icon-side-agreement: "\ea56";
$etabl-icon-side-about: "\ea57";
$etabl-icon-filter: "\ea4b";
$etabl-icon-location-marker: "\ea4a";
$etabl-icon-humburg: "\e9f7";
$etabl-icon-heart: "\ea46";
$etabl-icon-mobile_orders: "\ea1e";
$etabl-icon-mobile_catalog: "\ea1a";
$etabl-icon-mobile_profile: "\ea47";
$etabl-icon-mobile_basket: "\ea48";
$etabl-icon-mobile_home: "\ea1f";
$etabl-icon-cart-loyalty: "\e9ef";
$etabl-icon-card-contur: "\ea42";
$etabl-icon-clock-contur: "\ea43";
$etabl-icon-ok-contur: "\ea44";
$etabl-icon-cloud_chat: "\ea3d";
$etabl-icon-dialog_chat: "\ea3e";
$etabl-icon-idea_chat: "\ea3f";
$etabl-icon-phone_chat: "\ea40";
$etabl-icon-phone_white: "\ea41";
$etabl-icon-note: "\ea39";
$etabl-icon-shipping_wide: "\ea3a";
$etabl-icon-box_contur: "\ea3b";
$etabl-icon-shipping: "\ea3c";
$etabl-icon-basket-contur-fill: "\ea38";
$etabl-icon-new_delete_contur: "\ea2f";
$etabl-icon-000000001: "\ea21";
$etabl-icon-000000199: "\ea23";
$etabl-icon-000000252: "\ea24";
$etabl-icon-000000288: "\ea25";
$etabl-icon-000000342: "\ea26";
$etabl-icon-000000346: "\ea27";
$etabl-icon-000000365: "\ea29";
$etabl-icon-000000466: "\ea2a";
$etabl-icon-000000496: "\ea2b";
$etabl-icon-000000537: "\ea2c";
$etabl-icon-000000569: "\ea2d";
$etabl-icon-000000574: "\ea2e";
$etabl-icon-fv_menu: "\ea37";
$etabl-icon-about: "\ea30";
$etabl-icon-agreement: "\ea31";
$etabl-icon-policy: "\ea32";
$etabl-icon-shipping_and_payment: "\ea33";
$etabl-icon-exchenge: "\ea34";
$etabl-icon-phone_contur: "\ea35";
$etabl-icon-delete_contur: "\ea28";
$etabl-icon-search: "\ea16";
$etabl-icon-mobile_menu: "\ea22";
$etabl-icon-catalog_list: "\ea36";
$etabl-icon-warning_contur: "\ea49";
$etabl-icon-user_contur: "\ea20";
$etabl-icon-fv_contur: "\ea1b";
$etabl-icon-marker_contur: "\ea1d";
$etabl-icon-basket_contur: "\ea0e";
$etabl-icon-car_contur: "\ea0d";
$etabl-icon-point_green_half-path1: "\e900";
$etabl-icon-point_green_half-path2: "\e901";
$etabl-icon-point_green_half-path3: "\e902";
$etabl-icon-point_yellow_half-path1: "\e903";
$etabl-icon-point_yellow_half-path2: "\e904";
$etabl-icon-point_yellow_half-path3: "\e905";
$etabl-icon-point_yellow-path1: "\e906";
$etabl-icon-point_yellow-path2: "\e907";
$etabl-icon-point_gray-path1: "\ea12";
$etabl-icon-point_gray-path2: "\ea13";
$etabl-icon-point_green-path1: "\ea14";
$etabl-icon-point_green-path2: "\ea15";
$etabl-icon-point_completed-path1: "\e9f4";
$etabl-icon-point_completed-path2: "\e9f5";
$etabl-icon-point_completed-path3: "\e9f9";
$etabl-icon-000000156-path1: "\e909";
$etabl-icon-000000156-path2: "\e90a";
$etabl-icon-000000156-path3: "\e90b";
$etabl-icon-000000156-path4: "\e90c";
$etabl-icon-000000156-path5: "\e90d";
$etabl-icon-000000156-path6: "\e90e";
$etabl-icon-000000156-path7: "\e90f";
$etabl-icon-000000156-path8: "\e910";
$etabl-icon-000000156-path9: "\e911";
$etabl-icon-000000156-path10: "\e912";
$etabl-icon-000000156-path11: "\e913";
$etabl-icon-000000156-path12: "\e914";
$etabl-icon-000000156-path13: "\e915";
$etabl-icon-000000156-path14: "\e916";
$etabl-icon-000000156-path15: "\e917";
$etabl-icon-000000156-path16: "\e918";
$etabl-icon-000000156-path17: "\e919";
$etabl-icon-000000156-path18: "\e91a";
$etabl-icon-000000156-path19: "\e91b";
$etabl-icon-000000156-path20: "\e91c";
$etabl-icon-000000156-path21: "\e91d";
$etabl-icon-000000156-path22: "\e91e";
$etabl-icon-000000156-path23: "\e91f";
$etabl-icon-000000156-path24: "\e920";
$etabl-icon-000000156-path25: "\e921";
$etabl-icon-000000156-path26: "\e922";
$etabl-icon-000000156-path27: "\e923";
$etabl-icon-000000156-path28: "\e924";
$etabl-icon-000000156-path29: "\e925";
$etabl-icon-000000156-path30: "\e926";
$etabl-icon-000000156-path31: "\e927";
$etabl-icon-000000176-path1: "\e929";
$etabl-icon-000000176-path2: "\e92a";
$etabl-icon-000000176-path3: "\e92b";
$etabl-icon-000000176-path4: "\e92c";
$etabl-icon-000000176-path5: "\e92d";
$etabl-icon-000000176-path6: "\e92e";
$etabl-icon-000000176-path7: "\e92f";
$etabl-icon-000000176-path8: "\e930";
$etabl-icon-000000176-path9: "\e931";
$etabl-icon-000000176-path10: "\e932";
$etabl-icon-000000176-path11: "\e933";
$etabl-icon-000000176-path12: "\e934";
$etabl-icon-000000374-path1: "\e936";
$etabl-icon-000000374-path2: "\e937";
$etabl-icon-000000374-path3: "\e938";
$etabl-icon-000000374-path4: "\e939";
$etabl-icon-000000374-path5: "\e93a";
$etabl-icon-000000374-path6: "\e93b";
$etabl-icon-000000374-path7: "\e93c";
$etabl-icon-000000374-path8: "\e93d";
$etabl-icon-000000374-path9: "\e93e";
$etabl-icon-000000374-path10: "\e93f";
$etabl-icon-000000374-path11: "\e940";
$etabl-icon-000000374-path12: "\e941";
$etabl-icon-000000374-path13: "\e942";
$etabl-icon-000000374-path14: "\e943";
$etabl-icon-000000374-path15: "\e944";
$etabl-icon-000000374-path16: "\e945";
$etabl-icon-000000374-path17: "\e946";
$etabl-icon-000000374-path18: "\e947";
$etabl-icon-000000374-path19: "\e948";
$etabl-icon-000000374-path20: "\e949";
$etabl-icon-000000374-path21: "\e94a";
$etabl-icon-000000374-path22: "\e94b";
$etabl-icon-000000374-path23: "\e94c";
$etabl-icon-000000374-path24: "\e94d";
$etabl-icon-000000374-path25: "\e94e";
$etabl-icon-000000374-path26: "\e94f";
$etabl-icon-000000374-path27: "\e950";
$etabl-icon-000000374-path28: "\e951";
$etabl-icon-000000687-path1: "\e953";
$etabl-icon-000000687-path2: "\e954";
$etabl-icon-000000687-path3: "\e955";
$etabl-icon-000000687-path4: "\e956";
$etabl-icon-000000687-path5: "\e957";
$etabl-icon-000000687-path6: "\e958";
$etabl-icon-000000687-path7: "\e959";
$etabl-icon-000000687-path8: "\e95a";
$etabl-icon-000000687-path9: "\e95b";
$etabl-icon-000000687-path10: "\e95c";
$etabl-icon-000000687-path11: "\e95d";
$etabl-icon-000000687-path12: "\e95e";
$etabl-icon-000000687-path13: "\e95f";
$etabl-icon-000000687-path14: "\e960";
$etabl-icon-000000687-path15: "\e961";
$etabl-icon-000000687-path16: "\e962";
$etabl-icon-000000687-path17: "\e963";
$etabl-icon-000000687-path18: "\e964";
$etabl-icon-000000687-path19: "\e965";
$etabl-icon-000000687-path20: "\e966";
$etabl-icon-000000687-path21: "\e967";
$etabl-icon-000000687-path22: "\e968";
$etabl-icon-000000687-path23: "\e969";
$etabl-icon-000000687-path24: "\e96a";
$etabl-icon-000000687-path25: "\e96b";
$etabl-icon-000000687-path26: "\e96c";
$etabl-icon-000000687-path27: "\e96d";
$etabl-icon-000000687-path28: "\e96e";
$etabl-icon-000000687-path29: "\e96f";
$etabl-icon-000000687-path30: "\e970";
$etabl-icon-000000687-path31: "\e971";
$etabl-icon-000000687-path32: "\e972";
$etabl-icon-000000687-path33: "\e973";
$etabl-icon-000000687-path34: "\e974";
$etabl-icon-000000687-path35: "\e975";
$etabl-icon-000000687-path36: "\e976";
$etabl-icon-000000687-path37: "\e977";
$etabl-icon-000000687-path38: "\e978";
$etabl-icon-000000687-path39: "\e979";
$etabl-icon-000000687-path40: "\e97a";
$etabl-icon-000000687-path41: "\e97b";
$etabl-icon-000000687-path42: "\e97c";
$etabl-icon-000000687-path43: "\e97d";
$etabl-icon-000000687-path44: "\e97e";
$etabl-icon-000000687-path45: "\e97f";
$etabl-icon-000000687-path46: "\e980";
$etabl-icon-000000687-path47: "\e981";
$etabl-icon-000000914-path1: "\e983";
$etabl-icon-000000914-path2: "\e984";
$etabl-icon-000000914-path3: "\e985";
$etabl-icon-000000914-path4: "\e986";
$etabl-icon-000000914-path5: "\e987";
$etabl-icon-000000914-path6: "\e988";
$etabl-icon-000000914-path7: "\e989";
$etabl-icon-000000914-path8: "\e98a";
$etabl-icon-000000914-path9: "\e98b";
$etabl-icon-000000914-path10: "\e98c";
$etabl-icon-000000914-path11: "\e98d";
$etabl-icon-000000914-path12: "\e98e";
$etabl-icon-000000914-path13: "\e98f";
$etabl-icon-000000914-path14: "\e990";
$etabl-icon-000000914-path15: "\e991";
$etabl-icon-000000914-path16: "\e992";
$etabl-icon-000000914-path17: "\e993";
$etabl-icon-000000914-path18: "\e994";
$etabl-icon-000000914-path19: "\e995";
$etabl-icon-000000914-path20: "\e996";
$etabl-icon-000000914-path21: "\e997";
$etabl-icon-000000914-path22: "\e998";
$etabl-icon-000000914-path23: "\e999";
$etabl-icon-000000914-path24: "\e99a";
$etabl-icon-000000914-path25: "\e99b";
$etabl-icon-000000914-path26: "\e99c";
$etabl-icon-000000914-path27: "\e99d";
$etabl-icon-000000914-path28: "\e99e";
$etabl-icon-000000914-path29: "\e99f";
$etabl-icon-000000914-path30: "\e9a0";
$etabl-icon-000000914-path31: "\e9a1";
$etabl-icon-000000914-path32: "\e9a2";
$etabl-icon-000000914-path33: "\e9a3";
$etabl-icon-000000918-path1: "\e9a5";
$etabl-icon-000000918-path2: "\e9a6";
$etabl-icon-000000918-path3: "\e9a7";
$etabl-icon-000000918-path4: "\e9a8";
$etabl-icon-000000918-path5: "\e9a9";
$etabl-icon-000000918-path6: "\e9aa";
$etabl-icon-000000918-path7: "\e9ab";
$etabl-icon-000000918-path8: "\e9ac";
$etabl-icon-000000918-path9: "\e9ad";
$etabl-icon-000000918-path10: "\e9ae";
$etabl-icon-000000918-path11: "\e9af";
$etabl-icon-000000918-path12: "\e9b0";
$etabl-icon-000000918-path13: "\e9b1";
$etabl-icon-000000918-path14: "\e9b2";
$etabl-icon-000000918-path15: "\e9b3";
$etabl-icon-000000918-path16: "\e9b4";
$etabl-icon-000000918-path17: "\e9b5";
$etabl-icon-000000918-path18: "\e9b6";
$etabl-icon-000000918-path19: "\e9b7";
$etabl-icon-000000918-path20: "\e9b8";
$etabl-icon-000000918-path21: "\e9b9";
$etabl-icon-000000918-path22: "\e9ba";
$etabl-icon-000000918-path23: "\e9bb";
$etabl-icon-000000918-path24: "\e9bc";
$etabl-icon-000000918-path25: "\e9bd";
$etabl-icon-000000918-path26: "\e9be";
$etabl-icon-000000918-path27: "\e9bf";
$etabl-icon-000000919-path1: "\e9c1";
$etabl-icon-000000919-path2: "\e9c2";
$etabl-icon-000000919-path3: "\e9c3";
$etabl-icon-000000919-path4: "\e9c4";
$etabl-icon-000000919-path5: "\e9c5";
$etabl-icon-000000919-path6: "\e9c6";
$etabl-icon-000000919-path7: "\e9c7";
$etabl-icon-000000919-path8: "\e9c8";
$etabl-icon-000000919-path9: "\e9c9";
$etabl-icon-000000919-path10: "\e9ca";
$etabl-icon-000000919-path11: "\e9cb";
$etabl-icon-000000919-path12: "\e9cc";
$etabl-icon-000000919-path13: "\e9cd";
$etabl-icon-000000919-path14: "\e9ce";
$etabl-icon-000000919-path15: "\e9cf";
$etabl-icon-000000919-path16: "\e9d0";
$etabl-icon-000000919-path17: "\e9d1";
$etabl-icon-000000919-path18: "\e9d2";
$etabl-icon-000000919-path19: "\e9d3";
$etabl-icon-000000919-path20: "\e9d4";
$etabl-icon-000000919-path21: "\e9d5";
$etabl-icon-000000919-path22: "\e9d6";
$etabl-icon-000000919-path23: "\e9d7";
$etabl-icon-000000919-path24: "\e9d8";
$etabl-icon-000000919-path25: "\e9d9";
$etabl-icon-000000919-path26: "\e9da";
$etabl-icon-000000919-path27: "\e9db";
$etabl-icon-000000919-path28: "\e9dc";
$etabl-icon-000000919-path29: "\e9dd";
$etabl-icon-000000919-path30: "\e9de";
$etabl-icon-vk: "\e9fa";
$etabl-icon-ok: "\e9fb";
$etabl-icon-in: "\e9fe";
$etabl-icon-fb: "\e9ff";
$etabl-icon-gl: "\ea08";
$etabl-icon-qrcode: "\e9e7";
$etabl-icon-barcode: "\ea00";
$etabl-icon-bell: "\e9e9";
$etabl-icon-bonus: "\e9eb";
$etabl-icon-book: "\e9ec";
$etabl-icon-cancel: "\e9ed";
$etabl-icon-cloud: "\e9ee";
$etabl-icon-cursor: "\e9f0";
$etabl-icon-dialog: "\e9f2";
$etabl-icon-chat: "\ea01";
$etabl-icon-home: "\e9f8";
$etabl-icon-lamp: "\e9fc";
$etabl-icon-marker: "\ea09";
$etabl-icon-menu_short: "\ea0a";
$etabl-icon-menu: "\ea0b";
$etabl-icon-minus: "\ea0c";
$etabl-icon-orders: "\ea0f";
$etabl-icon-phone: "\ea10";
$etabl-icon-plus: "\ea11";
$etabl-icon-stock: "\ea17";
$etabl-icon-transport: "\ea18";
$etabl-icon-user: "\ea19";
$etabl-icon-cross: "\ea45";
$etabl-icon-delete: "\e9f1";
$etabl-icon-edit: "\e9f3";
$etabl-icon-basket: "\e9e8";
$etabl-icon-frost: "\ea60";
$etabl-icon-warning: "\ea1c";
$etabl-icon-list: "\e9fd";
$etabl-icon-blocks: "\e9ea";
$etabl-icon-arrow_prev: "\e9e1";
$etabl-icon-arrow_next: "\e9e0";
$etabl-icon-arrow_up: "\e9e2";
$etabl-icon-arrow_down: "\e9df";
$etabl-icon-000000914_short: "\e982";
$etabl-icon-000000918_short: "\e9a4";
$etabl-icon-000000919_short: "\e9c0";
$etabl-icon-000000687_short: "\e952";
$etabl-icon-000000374_short: "\e935";
$etabl-icon-000000176_short: "\e928";
$etabl-icon-000000156_short: "\e908";
$etabl-icon-question: "\ea02";
$etabl-icon-completed: "\ea03";
$etabl-icon-box: "\ea04";
$etabl-icon-calendar: "\ea05";
$etabl-icon-link: "\ea06";
$etabl-icon-update: "\ea07";
$etabl-icon-back_basket-path1: "\e9e3";
$etabl-icon-back_basket-path2: "\e9e4";
$etabl-icon-back_favourites-path1: "\e9e5";
$etabl-icon-back_favourites-path2: "\e9e6";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2l1rz0') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2l1rz0') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2l1rz0##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.etabl-icon-side-tg {
    &:before {
      content: $etabl-icon-side-tg; 
    }
}

.etabl-icon-eye {
    &:before {
      content: $etabl-icon-eye; 
    }
}

.etabl-icon-000000659 {
    &:before {
      content: $etabl-icon-000000659; 
    }
}

.etabl-icon-download_app {
    &:before {
      content: $etabl-icon-download_app; 
    }
}

.etabl-icon-ru-store {
    &:before {
      content: $etabl-icon-ru-store; 
    }
}

.etabl-icon-calendar_new {
    &:before {
      content: $etabl-icon-calendar_new; 
    }
}

.etabl-icon-point {
    &:before {
      content: $etabl-icon-point;
    }
}

.etabl-icon-glassesAccessibility {
    &:before {
      content: $etabl-icon-glassesAccessibility;
    }
}

.etabl-icon-rx_e {
    &:before {
      content: $etabl-icon-rx_e;
    }
}

.etabl-icon-app-gellary {
    &:before {
      content: $etabl-icon-app-gellary;
    }
}

.etabl-icon-app-store {
    &:before {
      content: $etabl-icon-app-store;
    }
}

.etabl-icon-google-play {
    &:before {
      content: $etabl-icon-google-play;
    }
}

.etabl-icon-ok-round {
    &:before {
      content: $etabl-icon-ok-round;
    }
}

.etabl-icon-side-articles-clear {
    &:before {
      content: $etabl-icon-side-articles-clear;
    }
}

.etabl-icon-whats-app {
    &:before {
      content: $etabl-icon-whats-app;
    }
}

.etabl-icon-copy-round {
    &:before {
      content: $etabl-icon-copy-round;
    }
}

.etabl-icon-prod-analogs {
    &:before {
      content: $etabl-icon-prod-analogs;
    }
}

.etabl-icon-prod-instruction {
    &:before {
      content: $etabl-icon-prod-instruction;
    }
}

.etabl-icon-prod-drugstores {
    &:before {
      content: $etabl-icon-prod-drugstores;
    }
}

.etabl-icon-prod-overall {
    &:before {
      content: $etabl-icon-prod-overall;
    }
}

.etabl-icon-prod-reviews {
    &:before {
      content: $etabl-icon-prod-reviews;
    }
}

.etabl-icon-prod-additional {
    &:before {
      content: $etabl-icon-prod-additional;
    }
}

.etabl-icon-prod-formfactor {
    &:before {
      content: $etabl-icon-prod-formfactor;
    }
}

.etabl-icon-side-articles {
    &:before {
      content: $etabl-icon-side-articles;
    }
}

.etabl-icon-ortoped {
    &:before {
      content: $etabl-icon-ortoped;
    }
}

.etabl-icon-beauty {
    &:before {
      content: $etabl-icon-beauty;
    }
}

.etabl-icon-optics {
    &:before {
      content: $etabl-icon-optics;
    }
}

.etabl-icon-vk-round {
    &:before {
      content: $etabl-icon-vk-round;
    }
}

.etabl-icon-telegram {
    &:before {
      content: $etabl-icon-telegram;
    }
}

.etabl-icon-devices {
    &:before {
      content: $etabl-icon-devices;
    }
}

.etabl-icon-virus {
    &:before {
      content: $etabl-icon-virus;
    }
}

.etabl-icon-vitamins {
    &:before {
      content: $etabl-icon-vitamins;
    }
}

.etabl-icon-all-night {
    &:before {
      content: $etabl-icon-all-night;
    }
}

.etabl-icon-wide-payment {
    &:before {
      content: $etabl-icon-wide-payment;
    }
}

.etabl-icon-plus-square {
    &:before {
      content: $etabl-icon-plus-square;
    }
}

.etabl-icon-edit-mobile {
    &:before {
      content: $etabl-icon-edit-mobile;
    }
}

.etabl-icon-rx-ok {
    &:before {
      content: $etabl-icon-rx-ok;
    }
}

.etabl-icon-settings {
    &:before {
      content: $etabl-icon-settings;
    }
}

.etabl-icon-side-partners {
    &:before {
      content: $etabl-icon-side-partners;
    }
}

.etabl-icon-business {
    &:before {
      content: $etabl-icon-business;
    }
}

.etabl-icon-franchise {
    &:before {
      content: $etabl-icon-franchise;
    }
}

.etabl-icon-pvz {
    &:before {
      content: $etabl-icon-pvz;
    }
}

.etabl-icon-attach {
    &:before {
      content: $etabl-icon-attach;
    }
}

.etabl-icon-notification {
    &:before {
      content: $etabl-icon-notification;
    }
}

.etabl-icon-scan {
    &:before {
      content: $etabl-icon-scan;
    }
}

.etabl-icon-rx {
    &:before {
        content: $etabl-icon-rx;
    }
}

.etabl-icon-message {
    &:before {
      content: $etabl-icon-message;
    }
}

.etabl-icon-coupon {
    &:before {
      content: $etabl-icon-coupon;
    }
}

.etabl-icon-copy {
    &:before {
      content: $etabl-icon-copy;
    }
}

.etabl-icon-exit {
    &:before {
      content: $etabl-icon-exit;
    }
}

.etabl-icon-share {
    &:before {
      content: $etabl-icon-share;
    }
}

.etabl-icon-share {
    &:before {
      content: $etabl-icon-share;
    }
}

.etabl-icon-ruble {
    &:before {
      content: $etabl-icon-ruble;
    }
}

.etabl-icon-side-sale {
    &:before {
      content: $etabl-icon-side-sale;
    }
}

.etabl-icon-long-arrow-down {
    &:before {
      content: $etabl-icon-long-arrow-down;
    }
}

.etabl-icon-long-arrow-up {
    &:before {
      content: $etabl-icon-long-arrow-up;
    }
}

.etabl-icon-info_warning {
    &:before {
      content: $etabl-icon-info_warning;
    }
}

.etabl-icon-char-filled {
    &:before {
      content: $etabl-icon-char-filled;
    }
}

.etabl-icon-chat-round {
    &:before {
      content: $etabl-icon-chat-round;
    }
}

.etabl-icon-side-paper {
    &:before {
      content: $etabl-icon-side-paper;
    }
}

.etabl-icon-star-fill {
    &:before {
      content: $etabl-icon-star-fill;
    }
}

.etabl-icon-star {
    &:before {
      content: $etabl-icon-star;
    }
}

.etabl-icon-VK {
    &:before {
      content: $etabl-icon-VK;
    }
}

.etabl-icon-return {
    &:before {
      content: $etabl-icon-return;
    }
}

.etabl-icon-delivery_two {
    &:before {
      content: $etabl-icon-delivery_two;
    }
}

.etabl-icon-delivery_one {
    &:before {
      content: $etabl-icon-delivery_one;
    }
}

.etabl-icon-000000613 {
    &:before {
      content: $etabl-icon-000000613;
    }
}

.etabl-icon-heart-mini {
    &:before {
      content: $etabl-icon-heart-mini;
    }
}

.etabl-icon-car_delivery {
    &:before {
      content: $etabl-icon-car_delivery;
    }
}
.etabl-icon-file-pdf {
    &:before {
      content: $etabl-icon-file-pdf;
    }
}
.etabl-icon-loading {
    &:before {
      content: $etabl-icon-loading;
    }
}
.etabl-icon-mortar-board {
    &:before {
      content: $etabl-icon-mortar-board;
    }
}
.etabl-icon-graph {
    &:before {
      content: $etabl-icon-graph;
    }
}
.etabl-icon-union {
    &:before {
      content: $etabl-icon-union;
    }
}
.etabl-icon-paintcan {
    &:before {
      content: $etabl-icon-paintcan;
    }
}
.etabl-icon-ellipse {
    &:before {
      content: $etabl-icon-ellipse;
    }
}
.etabl-icon-check-m .path1 {
    &:before {
      content: $etabl-icon-check-m-path1;
      color: rgb(255, 255, 255);
    }
}
.etabl-icon-check-m .path2 {
    &:before {
      content: $etabl-icon-check-m-path2;
      margin-left: -1em;
      color: rgb(12, 165, 81);
    }
}
.etabl-icon-profile-loyalty {
    &:before {
      content: $etabl-icon-profile-loyalty;
    }
}

.etabl-icon-side-loyalty {
    &:before {
      content: $etabl-icon-side-loyalty;
    }
}

.etabl-icon-side-delivery {
    &:before {
      content: $etabl-icon-side-delivery;
    }
}

.etabl-icon-side-policy {
    &:before {
      content: $etabl-icon-side-policy;
    }
}

.etabl-icon-side-return {
    &:before {
      content: $etabl-icon-side-return;
    }
}

.etabl-icon-side-vacancies {
    &:before {
      content: $etabl-icon-side-vacancies;
    }
}

.etabl-icon-side-contacts {
    &:before {
      content: $etabl-icon-side-contacts;
    }
}

.etabl-icon-side-payment {
    &:before {
      content: $etabl-icon-side-payment;
    }
}

.etabl-icon-side-locatons {
    &:before {
      content: $etabl-icon-side-locatons;
    }
}

.etabl-icon-side-how-order {
    &:before {
      content: $etabl-icon-side-how-order;
    }
}

.etabl-icon-side-faq {
    &:before {
      content: $etabl-icon-side-faq;
    }
}

.etabl-icon-side-agreement {
    &:before {
      content: $etabl-icon-side-agreement;
    }
}

.etabl-icon-side-about {
    &:before {
      content: $etabl-icon-side-about;
    }
}

.etabl-icon-filter {
    &:before {
      content: $etabl-icon-filter;
    }
}

.etabl-icon-location-marker {
    &:before {
      content: $etabl-icon-location-marker;
    }
}

.etabl-icon-humburg {
    &:before {
      content: $etabl-icon-humburg;
    }
}

.etabl-icon-heart {
    &:before {
      content: $etabl-icon-heart;
    }
}

.etabl-icon-mobile_orders {
    &:before {
      content: $etabl-icon-mobile_orders;
    }
}

.etabl-icon-mobile_catalog {
    &:before {
      content: $etabl-icon-mobile_catalog;
    }
}
.etabl-icon-side-mobile_catalog {
  &:before {
    content: $etabl-icon-mobile_catalog;
  }
}

.etabl-icon-mobile_profile {
    &:before {
      content: $etabl-icon-mobile_profile;
    }
}

.etabl-icon-side-mobile_profile {
  &:before {
    content: $etabl-icon-mobile_profile;
  }
}

.etabl-icon-mobile_basket {
    &:before {
      content: $etabl-icon-mobile_basket;
    }
}

.etabl-icon-mobile_home {
    &:before {
      content: $etabl-icon-mobile_home;
    }
}

.etabl-icon-cart-loyalty {
    &:before {
      content: $etabl-icon-cart-loyalty;
    }
}

.etabl-icon-card-contur {
    &:before {
      content: $etabl-icon-card-contur;
    }
}

.etabl-icon-clock-contur {
    &:before {
      content: $etabl-icon-clock-contur;
    }
}

.etabl-icon-ok-contur {
    &:before {
      content: $etabl-icon-ok-contur;
    }
}

.etabl-icon-cloud_chat {
    &:before {
      content: $etabl-icon-cloud_chat;
      color: #22BD77;
    }
}

.etabl-icon-dialog_chat {
    &:before {
      content: $etabl-icon-dialog_chat;
      color: #22BD77;
    }
}

.etabl-icon-idea_chat {
    &:before {
      content: $etabl-icon-idea_chat;
      color: #22BD77;
    }
}

.etabl-icon-phone_chat {
    &:before {
      content: $etabl-icon-phone_chat;
      color: #22BD77;
    }
}

.etabl-icon-phone_white {
    &:before {
      content: $etabl-icon-phone_white;
      color: #fff;
    }
}

.etabl-icon-note {
    &:before {
      content: $etabl-icon-note;
    }
}

.etabl-icon-shipping_wide {
    &:before {
      content: $etabl-icon-shipping_wide;
    }
}

.etabl-icon-box_contur {
    &:before {
      content: $etabl-icon-box_contur;
    }
}

.etabl-icon-shipping {
    &:before {
      content: $etabl-icon-shipping;
    }
}

.etabl-icon-basket-contur-fill {
    &:before {
      content: $etabl-icon-basket-contur-fill;
    }
}

.etabl-icon-new_delete_contur {
    &:before {
      content: $etabl-icon-new_delete_contur;
    }
}

.etabl-icon-000000001 {
    &:before {
      content: $etabl-icon-000000001;
    }
}

.etabl-icon-000000199 {
    &:before {
      content: $etabl-icon-000000199;
    }
}

.etabl-icon-000000252 {
    &:before {
      content: $etabl-icon-000000252;
    }
}

.etabl-icon-000000288 {
    &:before {
      content: $etabl-icon-000000288;
    }
}

.etabl-icon-000000342 {
    &:before {
      content: $etabl-icon-000000342;
    }
}

.etabl-icon-000000346 {
    &:before {
      content: $etabl-icon-000000346;
    }
}

.etabl-icon-000000365 {
    &:before {
      content: $etabl-icon-000000365;
    }
}

.etabl-icon-000000466 {
    &:before {
      content: $etabl-icon-000000466;
    }
}

.etabl-icon-000000496 {
    &:before {
      content: $etabl-icon-000000496;
    }
}

.etabl-icon-000000537 {
    &:before {
      content: $etabl-icon-000000537;
    }
}

.etabl-icon-000000569 {
    &:before {
      content: $etabl-icon-000000569;
    }
}

.etabl-icon-000000574 {
    &:before {
      content: $etabl-icon-000000574;
    }
}

.etabl-icon-fv_menu {
    &:before {
      content: $etabl-icon-fv_menu;
    }
}

.etabl-icon-about {
    &:before {
      content: $etabl-icon-about;
    }
}

.etabl-icon-agreement {
    &:before {
      content: $etabl-icon-agreement;
    }
}

.etabl-icon-policy {
    &:before {
      content: $etabl-icon-policy;
    }
}

.etabl-icon-shipping_and_payment {
    &:before {
      content: $etabl-icon-shipping_and_payment;
    }
}

.etabl-icon-exchenge {
    &:before {
      content: $etabl-icon-exchenge;
    }
}

.etabl-icon-phone_contur {
    &:before {
      content: $etabl-icon-phone_contur;
    }
}

.etabl-icon-delete_contur {
    &:before {
      content: $etabl-icon-delete_contur;
    }
}

.etabl-icon-search {
    &:before {
      content: $etabl-icon-search;
    }
}

.etabl-icon-catalog_list {
    &:before {
      content: $etabl-icon-catalog_list;
    }
}

.etabl-icon-side-catalog_list {
  &:before {
    content: $etabl-icon-catalog_list;
  }
}

.etabl-icon-mobile_menu {
    &:before {
      content: $etabl-icon-mobile_menu;
    }
}



.etabl-icon {
    font-family: '#{$icomoon-font-family}' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.etabl-icon-warning_contur {
    &:before {
      content: $etabl-icon-warning_contur;
    }
  }

.etabl-icon-user_contur {
    &:before {
        content: $etabl-icon-user_contur;
    }
}
.etabl-icon-side-user_contur {
  &:before {
    content: $etabl-icon-user_contur;
  }
}

.etabl-icon-fv_contur {
    &:before {
        content: $etabl-icon-fv_contur;
    }
}

.etabl-icon-marker_contur {
    &:before {
        content: $etabl-icon-marker_contur;
    }
}

.etabl-icon-basket_contur {
    &:before {
        content: $etabl-icon-basket_contur;
    }
}

.etabl-icon-car_contur {
    &:before {
        content: $etabl-icon-car_contur;
    }
}

.etabl-icon-point_green_half {
    .path1 {
        &:before {
            content: $etabl-icon-point_green_half-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_green_half-path2;
            margin-left: -1em;
            color: rgb(102, 194, 94);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-point_green_half-path3;
            margin-left: -1em;
            color: rgb(172, 172, 172);
        }
    }
}

.etabl-icon-point_yellow_half {
    .path1 {
        &:before {
            content: $etabl-icon-point_yellow_half-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_yellow_half-path2;
            margin-left: -1em;
            color: rgb(255, 175, 54);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-point_yellow_half-path3;
            margin-left: -1em;
            color: rgb(172, 172, 172);
        }
    }
}

.etabl-icon-point_yellow {
    .path1 {
        &:before {
            content: $etabl-icon-point_yellow-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_yellow-path2;
            margin-left: -1em;
            color: rgb(255, 175, 54);
        }
    }
}

.etabl-icon-point_gray {
    .path1 {
        &:before {
            content: $etabl-icon-point_gray-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_gray-path2;
            margin-left: -1em;
            color: rgb(172, 172, 172);
        }
    }
}

.etabl-icon-point_green {
    .path1 {
        &:before {
            content: $etabl-icon-point_green-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_green-path2;
            margin-left: -1em;
            color: rgb(102, 194, 94);
        }
    }
}

.etabl-icon-point_completed {
    .path1 {
        &:before {
            content: $etabl-icon-point_completed-path1;
            color: rgb(255, 255, 255);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-point_completed-path2;
            margin-left: -1em;
            color: rgb(102, 194, 94);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-point_completed-path3;
            margin-left: -1em;
            color: rgb(255, 255, 255);
        }
    }
}

.etabl-icon-000000156 {
    .path1 {
        &:before {
            content: $etabl-icon-000000156-path1;
            color: rgb(53, 152, 169);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000156-path2;
            margin-left: -1em;
            color: rgb(170, 231, 244);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000156-path3;
            margin-left: -1em;
            color: rgb(222, 242, 129);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000156-path4;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000156-path5;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000156-path6;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000156-path7;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000156-path8;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000156-path9;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000156-path10;
            margin-left: -1em;
            color: rgb(243, 253, 193);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000156-path11;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000156-path12;
            margin-left: -1em;
            color: none;
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000156-path13;
            margin-left: -1em;
            color: none;
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000156-path14;
            margin-left: -1em;
            color: none;
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000156-path15;
            margin-left: -1em;
            color: none;
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000156-path16;
            margin-left: -1em;
            color: none;
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000156-path17;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000156-path18;
            margin-left: -1em;
            color: rgb(122, 228, 118);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000156-path19;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000156-path20;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000156-path21;
            margin-left: -1em;
            color: rgb(122, 228, 118);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000156-path22;
            margin-left: -1em;
            color: rgb(122, 228, 118);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000156-path23;
            margin-left: -1em;
            color: rgb(194, 203, 67);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000156-path24;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000156-path25;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000156-path26;
            margin-left: -1em;
            color: rgb(254, 254, 220);
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000156-path27;
            margin-left: -1em;
            color: rgb(254, 254, 254);
        }
    }

    .path28 {
        &:before {
            content: $etabl-icon-000000156-path28;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path29 {
        &:before {
            content: $etabl-icon-000000156-path29;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path30 {
        &:before {
            content: $etabl-icon-000000156-path30;
            margin-left: -1em;
            color: rgb(222, 242, 129);
        }
    }

    .path31 {
        &:before {
            content: $etabl-icon-000000156-path31;
            margin-left: -1em;
            color: rgb(157, 244, 153);
        }
    }
}

.etabl-icon-000000176 {
    .path1 {
        &:before {
            content: $etabl-icon-000000176-path1;
            color: rgb(171, 231, 245);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000176-path2;
            margin-left: -1em;
            color: rgb(171, 231, 245);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000176-path3;
            margin-left: -1em;
            color: rgb(127, 229, 125);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000176-path4;
            margin-left: -1em;
            color: rgb(127, 229, 125);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000176-path5;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000176-path6;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000176-path7;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000176-path8;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000176-path9;
            margin-left: -1em;
            color: rgb(230, 238, 126);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000176-path10;
            margin-left: -1em;
            color: rgb(230, 238, 126);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000176-path11;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000176-path12;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }
}

.etabl-icon-000000374 {
    .path1 {
        &:before {
            content: $etabl-icon-000000374-path1;
            color: rgb(53, 152, 169);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000374-path2;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000374-path3;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000374-path4;
            margin-left: -1em;
            color: rgb(125, 228, 122);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000374-path5;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000374-path6;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000374-path7;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000374-path8;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000374-path9;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000374-path10;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000374-path11;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000374-path12;
            margin-left: -1em;
            color: rgb(170, 231, 244);
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000374-path13;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000374-path14;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000374-path15;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000374-path16;
            margin-left: -1em;
            color: rgb(212, 219, 140);
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000374-path17;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000374-path18;
            margin-left: -1em;
            color: rgb(170, 231, 244);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000374-path19;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000374-path20;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000374-path21;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000374-path22;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000374-path23;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000374-path24;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000374-path25;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000374-path26;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000374-path27;
            margin-left: -1em;
            color: rgb(229, 236, 154);
        }
    }

    .path28 {
        &:before {
            content: $etabl-icon-000000374-path28;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }
}

.etabl-icon-000000687 {
    .path1 {
        &:before {
            content: $etabl-icon-000000687-path1;
            color: rgb(244, 251, 220);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000687-path2;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000687-path3;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000687-path4;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000687-path5;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000687-path6;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000687-path7;
            margin-left: -1em;
            color: rgb(97, 183, 196);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000687-path8;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000687-path9;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000687-path10;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000687-path11;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000687-path12;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000687-path13;
            margin-left: -1em;
            color: rgb(139, 210, 224);
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000687-path14;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000687-path15;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000687-path16;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000687-path17;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000687-path18;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000687-path19;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000687-path20;
            margin-left: -1em;
            color: none;
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000687-path21;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000687-path22;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000687-path23;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000687-path24;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000687-path25;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000687-path26;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000687-path27;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path28 {
        &:before {
            content: $etabl-icon-000000687-path28;
            margin-left: -1em;
            color: rgb(122, 228, 119);
        }
    }

    .path29 {
        &:before {
            content: $etabl-icon-000000687-path29;
            margin-left: -1em;
            color: rgb(30, 218, 89);
        }
    }

    .path30 {
        &:before {
            content: $etabl-icon-000000687-path30;
            margin-left: -1em;
            color: rgb(122, 228, 119);
        }
    }

    .path31 {
        &:before {
            content: $etabl-icon-000000687-path31;
            margin-left: -1em;
            color: rgb(30, 218, 89);
        }
    }

    .path32 {
        &:before {
            content: $etabl-icon-000000687-path32;
            margin-left: -1em;
            color: rgb(185, 195, 54);
        }
    }

    .path33 {
        &:before {
            content: $etabl-icon-000000687-path33;
            margin-left: -1em;
            color: rgb(200, 223, 78);
        }
    }

    .path34 {
        &:before {
            content: $etabl-icon-000000687-path34;
            margin-left: -1em;
            color: rgb(194, 203, 68);
        }
    }

    .path35 {
        &:before {
            content: $etabl-icon-000000687-path35;
            margin-left: -1em;
            color: rgb(225, 234, 127);
        }
    }

    .path36 {
        &:before {
            content: $etabl-icon-000000687-path36;
            margin-left: -1em;
            color: none;
        }
    }

    .path37 {
        &:before {
            content: $etabl-icon-000000687-path37;
            margin-left: -1em;
            color: none;
        }
    }

    .path38 {
        &:before {
            content: $etabl-icon-000000687-path38;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path39 {
        &:before {
            content: $etabl-icon-000000687-path39;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path40 {
        &:before {
            content: $etabl-icon-000000687-path40;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path41 {
        &:before {
            content: $etabl-icon-000000687-path41;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path42 {
        &:before {
            content: $etabl-icon-000000687-path42;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path43 {
        &:before {
            content: $etabl-icon-000000687-path43;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path44 {
        &:before {
            content: $etabl-icon-000000687-path44;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path45 {
        &:before {
            content: $etabl-icon-000000687-path45;
            margin-left: -1em;
            color: rgb(245, 251, 154);
        }
    }

    .path46 {
        &:before {
            content: $etabl-icon-000000687-path46;
            margin-left: -1em;
            color: rgb(165, 242, 163);
        }
    }

    .path47 {
        &:before {
            content: $etabl-icon-000000687-path47;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }
}

.etabl-icon-000000914 {
    .path1 {
        &:before {
            content: $etabl-icon-000000914-path1;
            color: rgb(54, 152, 169);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000914-path2;
            margin-left: -1em;
            color: none;
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000914-path3;
            margin-left: -1em;
            color: none;
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000914-path4;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000914-path5;
            margin-left: -1em;
            color: none;
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000914-path6;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000914-path7;
            margin-left: -1em;
            color: rgb(232, 236, 127);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000914-path8;
            margin-left: -1em;
            color: rgb(200, 223, 78);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000914-path9;
            margin-left: -1em;
            color: rgb(170, 231, 244);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000914-path10;
            margin-left: -1em;
            color: rgb(200, 223, 78);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000914-path11;
            margin-left: -1em;
            color: rgb(200, 223, 78);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000914-path12;
            margin-left: -1em;
            color: none;
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000914-path13;
            margin-left: -1em;
            color: rgb(232, 236, 127);
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000914-path14;
            margin-left: -1em;
            color: rgb(232, 236, 127);
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000914-path15;
            margin-left: -1em;
            color: none;
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000914-path16;
            margin-left: -1em;
            color: none;
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000914-path17;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000914-path18;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000914-path19;
            margin-left: -1em;
            color: rgb(30, 218, 89);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000914-path20;
            margin-left: -1em;
            color: rgb(253, 255, 194);
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000914-path21;
            margin-left: -1em;
            color: rgb(253, 255, 194);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000914-path22;
            margin-left: -1em;
            color: rgb(253, 255, 196);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000914-path23;
            margin-left: -1em;
            color: rgb(255, 255, 243);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000914-path24;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000914-path25;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000914-path26;
            margin-left: -1em;
            color: rgb(50, 197, 76);
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000914-path27;
            margin-left: -1em;
            color: rgb(50, 197, 76);
        }
    }

    .path28 {
        &:before {
            content: $etabl-icon-000000914-path28;
            margin-left: -1em;
            color: rgb(122, 228, 119);
        }
    }

    .path29 {
        &:before {
            content: $etabl-icon-000000914-path29;
            margin-left: -1em;
            color: rgb(157, 244, 153);
        }
    }

    .path30 {
        &:before {
            content: $etabl-icon-000000914-path30;
            margin-left: -1em;
            color: rgb(179, 244, 255);
        }
    }

    .path31 {
        &:before {
            content: $etabl-icon-000000914-path31;
            margin-left: -1em;
            color: rgb(208, 248, 255);
        }
    }

    .path32 {
        &:before {
            content: $etabl-icon-000000914-path32;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }

    .path33 {
        &:before {
            content: $etabl-icon-000000914-path33;
            margin-left: -1em;
            color: rgb(54, 152, 169);
        }
    }
}

.etabl-icon-000000918 {
    .path1 {
        &:before {
            content: $etabl-icon-000000918-path1;
            color: rgb(198, 246, 253);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000918-path2;
            margin-left: -1em;
            color: rgb(171, 231, 245);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000918-path3;
            margin-left: -1em;
            color: rgb(131, 204, 220);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000918-path4;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000918-path5;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000918-path6;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000918-path7;
            margin-left: -1em;
            color: rgb(131, 204, 220);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000918-path8;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000918-path9;
            margin-left: -1em;
            color: rgb(171, 231, 245);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000918-path10;
            margin-left: -1em;
            color: rgb(122, 195, 212);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000918-path11;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000918-path12;
            margin-left: -1em;
            color: rgb(28, 217, 88);
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000918-path13;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000918-path14;
            margin-left: -1em;
            color: rgb(122, 228, 118);
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000918-path15;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000918-path16;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000918-path17;
            margin-left: -1em;
            color: rgb(222, 242, 130);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000918-path18;
            margin-left: -1em;
            color: rgb(53, 152, 169);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000918-path19;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000918-path20;
            margin-left: -1em;
            color: rgb(222, 242, 130);
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000918-path21;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000918-path22;
            margin-left: -1em;
            color: rgb(198, 246, 253);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000918-path23;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000918-path24;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000918-path25;
            margin-left: -1em;
            color: rgb(200, 222, 77);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000918-path26;
            margin-left: -1em;
            color: rgb(234, 252, 255);
            opacity: 0.68;
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000918-path27;
            margin-left: -1em;
            color: none;
        }
    }
}

.etabl-icon-000000919 {
    .path1 {
        &:before {
            content: $etabl-icon-000000919-path1;
            color: rgb(149, 220, 235);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-000000919-path2;
            margin-left: -1em;
            color: rgb(180, 245, 255);
        }
    }

    .path3 {
        &:before {
            content: $etabl-icon-000000919-path3;
            margin-left: -1em;
            color: rgb(149, 220, 235);
        }
    }

    .path4 {
        &:before {
            content: $etabl-icon-000000919-path4;
            margin-left: -1em;
            color: rgb(149, 220, 235);
        }
    }

    .path5 {
        &:before {
            content: $etabl-icon-000000919-path5;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path6 {
        &:before {
            content: $etabl-icon-000000919-path6;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path7 {
        &:before {
            content: $etabl-icon-000000919-path7;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path8 {
        &:before {
            content: $etabl-icon-000000919-path8;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path9 {
        &:before {
            content: $etabl-icon-000000919-path9;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path10 {
        &:before {
            content: $etabl-icon-000000919-path10;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path11 {
        &:before {
            content: $etabl-icon-000000919-path11;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path12 {
        &:before {
            content: $etabl-icon-000000919-path12;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path13 {
        &:before {
            content: $etabl-icon-000000919-path13;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path14 {
        &:before {
            content: $etabl-icon-000000919-path14;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path15 {
        &:before {
            content: $etabl-icon-000000919-path15;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path16 {
        &:before {
            content: $etabl-icon-000000919-path16;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path17 {
        &:before {
            content: $etabl-icon-000000919-path17;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path18 {
        &:before {
            content: $etabl-icon-000000919-path18;
            margin-left: -1em;
            color: rgb(180, 245, 255);
        }
    }

    .path19 {
        &:before {
            content: $etabl-icon-000000919-path19;
            margin-left: -1em;
            color: rgb(180, 245, 255);
        }
    }

    .path20 {
        &:before {
            content: $etabl-icon-000000919-path20;
            margin-left: -1em;
            color: rgb(227, 251, 254);
        }
    }

    .path21 {
        &:before {
            content: $etabl-icon-000000919-path21;
            margin-left: -1em;
            color: rgb(227, 251, 254);
        }
    }

    .path22 {
        &:before {
            content: $etabl-icon-000000919-path22;
            margin-left: -1em;
            color: rgb(191, 218, 49);
        }
    }

    .path23 {
        &:before {
            content: $etabl-icon-000000919-path23;
            margin-left: -1em;
            color: rgb(191, 218, 49);
        }
    }

    .path24 {
        &:before {
            content: $etabl-icon-000000919-path24;
            margin-left: -1em;
            color: rgb(52, 152, 169);
        }
    }

    .path25 {
        &:before {
            content: $etabl-icon-000000919-path25;
            margin-left: -1em;
            color: rgb(38, 219, 93);
        }
    }

    .path26 {
        &:before {
            content: $etabl-icon-000000919-path26;
            margin-left: -1em;
            color: rgb(38, 219, 93);
        }
    }

    .path27 {
        &:before {
            content: $etabl-icon-000000919-path27;
            margin-left: -1em;
            color: rgb(255, 255, 220);
        }
    }

    .path28 {
        &:before {
            content: $etabl-icon-000000919-path28;
            margin-left: -1em;
            color: rgb(255, 255, 220);
        }
    }

    .path29 {
        &:before {
            content: $etabl-icon-000000919-path29;
            margin-left: -1em;
            color: rgb(38, 219, 93);
        }
    }

    .path30 {
        &:before {
            content: $etabl-icon-000000919-path30;
            margin-left: -1em;
            color: rgb(114, 234, 150);
        }
    }
}

.etabl-icon-vk {
    &:before {
        content: $etabl-icon-vk;
    }
}

.etabl-icon-ok {
    &:before {
        content: $etabl-icon-ok;
    }
}

.etabl-icon-in {
    &:before {
        content: $etabl-icon-in;
    }
}

.etabl-icon-fb {
    &:before {
        content: $etabl-icon-fb;
    }
}

.etabl-icon-gl {
    &:before {
        content: $etabl-icon-gl;
    }
}

.etabl-icon-qrcode {
    &:before {
        content: $etabl-icon-qrcode;
    }
}

.etabl-icon-barcode {
    &:before {
        content: $etabl-icon-barcode;
    }
}

.etabl-icon-bell {
    &:before {
        content: $etabl-icon-bell;
    }
}

.etabl-icon-bonus {
    &:before {
        content: $etabl-icon-bonus;
    }
}

.etabl-icon-book {
    &:before {
        content: $etabl-icon-book;
    }
}

.etabl-icon-cancel {
    &:before {
        content: $etabl-icon-cancel;
    }
}

.etabl-icon-cloud {
    &:before {
        content: $etabl-icon-cloud;
    }
}

.etabl-icon-cursor {
    &:before {
        content: $etabl-icon-cursor;
    }
}

.etabl-icon-dialog {
    &:before {
        content: $etabl-icon-dialog;
    }
}

.etabl-icon-chat {
    &:before {
        content: $etabl-icon-chat;
    }
}

.etabl-icon-home {
    &:before {
        content: $etabl-icon-home;
    }
}

.etabl-icon-lamp {
    &:before {
        content: $etabl-icon-lamp;
    }
}

.etabl-icon-marker {
    &:before {
        content: $etabl-icon-marker;
    }
}

.etabl-icon-menu_short {
    &:before {
        content: $etabl-icon-menu_short;
    }
}

.etabl-icon-menu {
    &:before {
        content: $etabl-icon-menu;
    }
}

.etabl-icon-minus {
    &:before {
        content: $etabl-icon-minus;
    }
}

.etabl-icon-orders {
    &:before {
        content: $etabl-icon-orders;
    }
}

.etabl-icon-phone {
    &:before {
        content: $etabl-icon-phone;
    }
}

.etabl-icon-plus {
    &:before {
        content: $etabl-icon-plus;
    }
}

.etabl-icon-stock {
    &:before {
        content: $etabl-icon-stock;
    }
}

.etabl-icon-transport {
    &:before {
        content: $etabl-icon-transport;
    }
}

.etabl-icon-user {
    &:before {
        content: $etabl-icon-user;
    }
}
.etabl-icon-side-user {
  &:before {
    content: $etabl-icon-user;
  }
}

.etabl-icon-cross {
    &:before {
        content: $etabl-icon-cross;
    }
}

.etabl-icon-delete {
    &:before {
        content: $etabl-icon-delete;
    }
}

.etabl-icon-edit {
    &:before {
        content: $etabl-icon-edit;
    }
}

.etabl-icon-basket {
    &:before {
        content: $etabl-icon-basket;
    }
}

.etabl-icon-frost {
    &:before {
      content: $etabl-icon-frost;
    }
}

.etabl-icon-warning {
    &:before {
        content: $etabl-icon-warning;
    }
}

.etabl-icon-list {
    &:before {
        content: $etabl-icon-list;
    }
}

.etabl-icon-blocks {
    &:before {
        content: $etabl-icon-blocks;
    }
}

.etabl-icon-arrow_prev {
    &:before {
        content: $etabl-icon-arrow_prev;
    }
}

.etabl-icon-arrow_next {
    &:before {
        content: $etabl-icon-arrow_next;
    }
}

.etabl-icon-arrow_up {
    &:before {
        content: $etabl-icon-arrow_up;
    }
}

.etabl-icon-arrow_down {
    &:before {
        content: $etabl-icon-arrow_down;
    }
}

.etabl-icon-000000914_short {
    &:before {
        content: $etabl-icon-000000914_short;
    }
}

.etabl-icon-000000918_short {
    &:before {
        content: $etabl-icon-000000918_short;
    }
}

.etabl-icon-000000919_short {
    &:before {
        content: $etabl-icon-000000919_short;
    }
}

.etabl-icon-000000687_short {
    &:before {
        content: $etabl-icon-000000687_short;
    }
}

.etabl-icon-000000374_short {
    &:before {
        content: $etabl-icon-000000374_short;
    }
}

.etabl-icon-000000176_short {
    &:before {
        content: $etabl-icon-000000176_short;
    }
}

.etabl-icon-000000156_short {
    &:before {
        content: $etabl-icon-000000156_short;
    }
}

.etabl-icon-question {
    &:before {
        content: $etabl-icon-question;
    }
}

.etabl-icon-completed {
    &:before {
        content: $etabl-icon-completed;
    }
}

.etabl-icon-box {
    &:before {
        content: $etabl-icon-box;
    }
}

.etabl-icon-calendar {
    &:before {
        content: $etabl-icon-calendar;
    }
}

.etabl-icon-link {
    &:before {
        content: $etabl-icon-link;
    }
}

.etabl-icon-update {
    &:before {
        content: $etabl-icon-update;
    }
}

.etabl-icon-back_basket {
    .path1 {
        &:before {
            content: $etabl-icon-back_basket-path1;
            color: rgb(225, 225, 225);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-back_basket-path2;
            margin-left: -1em;
            color: rgb(255, 255, 255);
        }
    }
}

.etabl-icon-back_favourites {
    .path1 {
        &:before {
            content: $etabl-icon-back_favourites-path1;
            color: rgb(225, 225, 225);
        }
    }

    .path2 {
        &:before {
            content: $etabl-icon-back_favourites-path2;
            margin-left: -1em;
            color: rgb(255, 255, 255);
        }
    }
}
